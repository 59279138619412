import React, { Suspense, lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import DashboardLayout from "../layouts/dashboard";
import ProtectedRoute from "../layouts/protected-route";
import ContainerLayout from "../layouts/container";
import ProductLayout from "../layouts/product";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks

  return (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<ContainerLayout />}>
        <Route element={<Navigate to="home" replace />} index />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          <Route path="home" element={<HomePage />} />
          <Route path="system-configs">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<SystemConfigsPage />} />
            <Route path="form/:id" element={<SystemConfigsFormPage />} />
          </Route>
          <Route path="categories">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<CategoryListPage />} />
            <Route path="form/:id" element={<CategoryFormPage />} />
          </Route>
          <Route path="locales">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<LocaleListPage />} />
            <Route path="form/:id" element={<LocaleFormPage />} />
          </Route>
          <Route path="localizations">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<LocalizationListPage />} />
            <Route path="form/:id" element={<LocalizationFormPage />} />
          </Route>
          <Route path="milestones">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<MilestonesListPage />} />
            <Route path="form/:id" element={<MilestonesFormPage />} />
          </Route>
          <Route path="news">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<NewsListPage />} />
            <Route path="form/:id" element={<NewsFormPage />} />
          </Route>
          <Route path="our-core-values">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<OurCoreValuesListPage />} />
            <Route path="form/:id" element={<OurCoreValuesFormPage />} />
          </Route>
          <Route path="social-responsibilities">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<SocialResponsibilitiesListPage />} />
            <Route path="form/:id" element={<SocialResponsibilitiesFormPage />} />
          </Route>
          <Route path="projects">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ProjectsListPage />} />
            <Route path="form/:id" element={<ProjectsFormPage />} />
          </Route>
          <Route path="services">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ServicesListPage />} />
            <Route path="form/:id" element={<ServicesFormPage />} />
          </Route>
          <Route path="partners">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<PartnersListPage />} />
            <Route path="form/:id" element={<PartnersFormPage />} />
          </Route>
          <Route path="teams">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<TeamsListPage />} />
            <Route path="form/:id" element={<TeamsFormPage />} />
          </Route>
          <Route path="users">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<UsersListPage />} />
            <Route path="form/:id" element={<UsersFormPage />} />
          </Route>
          <Route path="roles">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<RolesListPage />} />
            <Route path="form/:id" element={<RolesFormPage />} />
          </Route>
          <Route path="article">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ArticleListPage />} />
            <Route path="form/:id" element={<ArticleFormPage />} />
          </Route>
          <Route path="attribute">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<AttributeListPage />} />
            <Route path="form/:id" element={<AttributeFormPage />} />
          </Route>
          <Route path="product">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ProductListPage />} />
            <Route path="form/:id" element={<ProductFormPage />} />
          </Route>
          <Route path="project">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ProjectListPage />} />
            <Route path="form/:id" element={<ProjectFormPage />} />
          </Route>
          <Route path="application">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<ApplicationListPage />} />
            <Route path="form/:id" element={<ApplicationFormPage />} />
          </Route>
          <Route path="slider">
            <Route element={<Navigate to="list" replace />} index />
            <Route path="list" element={<SliderListPage />} />
            <Route path="form/:id" element={<SliderFormPage />} />
          </Route>
        </Route>
        <Route path="home">
          <Route index element={<Home />} />
        </Route>
        <Route path="about">
          <Route index element={<About />} />
        </Route>
        <Route path="projects">
          <Route index element={<Projects />} />
          <Route path="detail/:slug-:id" element={<ProjectsDetail />} />
        </Route>
        <Route path="property-management">
          <Route index element={<PropertyManagement />} />
        </Route>
        <Route path="social-responsibility">
          <Route index element={<SocialResponsibilities />} />
          <Route
            path="detail/:slug-:id"
            element={<SocialResponsibilityDetail />}
          />
        </Route>

        <Route path="contact">
          <Route index element={<Contact />} />
        </Route>
        <Route path="user">
          <Route path={`login`} element={<LoginPage />} />
        </Route>
      </Route>
    </Routes>
  );
}
const HomePage = Loadable(lazy(() => import("../pages/home")));
const SystemConfigsPage = Loadable(
  lazy(() => import("../pages/admin/system-configs/list"))
);
const SystemConfigsFormPage = Loadable(
  lazy(() => import("../pages/admin/system-configs/form"))
);

const ArticleListPage = Loadable(
  lazy(() => import("../pages/admin/article/list"))
);
const ArticleFormPage = Loadable(
  lazy(() => import("../pages/admin/article/form"))
);

const ApplicationListPage = Loadable(
  lazy(() => import("../pages/admin/application/list"))
);
const ApplicationFormPage = Loadable(
  lazy(() => import("../pages/admin/application/form"))
);

const CategoryListPage = Loadable(
  lazy(() => import("../pages/admin/category/list"))
);
const CategoryFormPage = Loadable(
  lazy(() => import("../pages/admin/category/form"))
);

const LocaleListPage = Loadable(
  lazy(() => import("../pages/admin/locales/list"))
);
const LocaleFormPage = Loadable(
  lazy(() => import("../pages/admin/locales/form"))
);

const LocalizationListPage = Loadable(
  lazy(() => import("../pages/admin/localizations/list"))
);
const LocalizationFormPage = Loadable(
  lazy(() => import("../pages/admin/localizations/form"))
);

const MilestonesListPage = Loadable(
  lazy(() => import("../pages/admin/milestones/list"))
);
const MilestonesFormPage = Loadable(
  lazy(() => import("../pages/admin/milestones/form"))
);

const NewsListPage = Loadable(lazy(() => import("../pages/admin/news/list")));
const NewsFormPage = Loadable(lazy(() => import("../pages/admin/news/form")));

const OurCoreValuesListPage = Loadable(
  lazy(() => import("../pages/admin/our-core-values/list"))
);
const OurCoreValuesFormPage = Loadable(
  lazy(() => import("../pages/admin/our-core-values/form"))
);

const SocialResponsibilitiesListPage = Loadable(
  lazy(() => import('../pages/admin/social-responsibilities/list'))
);
const SocialResponsibilitiesFormPage = Loadable(
  lazy(() => import('../pages/admin/social-responsibilities/form'))
);

const ProjectsListPage = Loadable(
  lazy(() => import("../pages/admin/projects/list"))
);
const ProjectsFormPage = Loadable(
  lazy(() => import("../pages/admin/projects/form"))
);

const ServicesListPage = Loadable(
  lazy(() => import("../pages/admin/services/list"))
);
const ServicesFormPage = Loadable(
  lazy(() => import("../pages/admin/services/form"))
);

const PartnersListPage = Loadable(
  lazy(() => import("../pages/admin/partners/list"))
);
const PartnersFormPage = Loadable(
  lazy(() => import("../pages/admin/partners/form"))
);

const TeamsListPage = Loadable(lazy(() => import("../pages/admin/teams/list")));
const TeamsFormPage = Loadable(lazy(() => import("../pages/admin/teams/form")));

const UsersListPage = Loadable(lazy(() => import("../pages/admin/users/list")));
const UsersFormPage = Loadable(lazy(() => import("../pages/admin/users/form")));

const RolesListPage = Loadable(lazy(() => import("../pages/admin/roles/list")));
const RolesFormPage = Loadable(lazy(() => import("../pages/admin/roles/form")));

const AttributeListPage = Loadable(
  lazy(() => import("../pages/admin/attribute/list"))
);
const AttributeFormPage = Loadable(
  lazy(() => import("../pages/admin/attribute/form"))
);

const ProductListPage = Loadable(
  lazy(() => import("../pages/admin/product/list"))
);
const ProductFormPage = Loadable(
  lazy(() => import("../pages/admin/product/form"))
);

const ProjectListPage = Loadable(
  lazy(() => import("../pages/admin/project/list"))
);
const ProjectFormPage = Loadable(
  lazy(() => import("../pages/admin/project/form"))
);

const SettingListPage = Loadable(
  lazy(() => import("../pages/admin/setting/list"))
);
const SettingFormPage = Loadable(
  lazy(() => import("../pages/admin/setting/form"))
);

const SliderListPage = Loadable(
  lazy(() => import("../pages/admin/slider/list"))
);
const SliderFormPage = Loadable(
  lazy(() => import("../pages/admin/slider/form"))
);

const FormPage = Loadable(lazy(() => import("../pages/admin/test")));
const ListPage = Loadable(lazy(() => import("../pages/admin/test/list")));

const LoginPage = Loadable(lazy(() => import("../pages/user/Login")));

const Home = Loadable(lazy(() => import("../pages/guest/home")));
const About = Loadable(lazy(() => import("../pages/guest/about")));
const Projects = Loadable(lazy(() => import("../pages/guest/projects")));
const ProjectsDetail = Loadable(
  lazy(() => import("../pages/guest/projects/detail"))
);
const PropertyManagement = Loadable(
  lazy(() => import("../pages/guest/property-management"))
);
const SocialResponsibilities = Loadable(
  lazy(() => import("../pages/guest/social-responsibility"))
);
const SocialResponsibilityDetail = Loadable(
  lazy(() => import("../pages/guest/social-responsibility/detail"))
);

const News = Loadable(lazy(() => import("../pages/guest/news")));
const NewsDetail = Loadable(lazy(() => import("../pages/guest/news/detail")));

const Contact = Loadable(lazy(() => import("../pages/guest/contact")));

const PageTwo = Loadable(lazy(() => import("../pages/PageTwo")));
const PageThree = Loadable(lazy(() => import("../pages/PageThree")));
const PageFour = Loadable(lazy(() => import("../pages/PageFour")));
const PageFive = Loadable(lazy(() => import("../pages/PageFive")));
const PageSix = Loadable(lazy(() => import("../pages/PageSix")));
