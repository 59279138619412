// routes
import Router from './routes/index';
import ThemeProvider from './theme';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';

export default function App() {
  i18n
    .use(initReactI18next)
    .init({
      resources: {},
      interpolation: {
        escapeValue: false,
      },
    });

  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider>
        <NotistackProvider>
          <ProgressBarStyle />
          <Router />
        </NotistackProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}
