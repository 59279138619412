import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDashboardContext } from '../container/context';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ProjectedRoute = ({ children }) => {
  const { user, onGetAuthenticatedUser } = useDashboardContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.username) {
      onGetAuthenticatedUser().catch((err) => {
        navigate('/user/login');
      });
    }
  }, []);

  return children;
};

export default ProjectedRoute;
