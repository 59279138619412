// @mui
import { Box } from "@mui/material";

// ----------------------------------------------------------------------

export default function UploadIllustration({ ...other }) {
  return (
    <Box>
      <svg
        width="406"
        height="76"
        viewBox="0 0 406 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...other}
      >
        <g clipPath="url(#clip0_63_1289)">
          <path
            d="M0 0.716675H5.77111V29.4222H21.4416V34.9019H0V0.716675Z"
            fill="url(#paint0_linear_63_1289)"
          />
          <path
            d="M86.3984 30.5602C84.924 33.0471 81.3855 35.6184 75.9514 35.6184C67.0209 35.6184 59.1436 29.0849 59.1436 17.7881C59.1436 6.4914 67.4422 0 76.3305 0C84.5449 0 89.9369 4.80532 91.7482 10.4115L86.3141 12.477C85.1346 8.599 81.7646 5.3533 76.3305 5.3533C70.8964 5.3533 64.9989 9.31558 64.9989 17.7881C64.9989 26.2607 70.3909 30.3073 76.2884 30.3073C83.1548 30.3073 85.8086 25.5441 86.0614 22.6356H74.7719V17.5774H91.4955V34.9018H86.8196L86.3984 30.5602Z"
            fill="url(#paint1_linear_63_1289)"
          />
          <path
            d="M182.485 0.716675L190.658 15.0483L198.914 0.716675H205.401L193.354 20.4438V34.9019H187.582V20.4438L175.661 0.716675H182.485Z"
            fill="url(#paint2_linear_63_1289)"
          />
          <path
            d="M233.456 9.02061V34.9019H227.685V0.716675H235.057L250.559 24.9541V0.716675H256.33V34.9019H250.306L233.456 9.02061Z"
            fill="url(#paint3_linear_63_1289)"
          />
          <path
            d="M283.711 0C292.684 0 301.109 6.61786 301.109 17.7881C301.109 28.9584 292.684 35.6184 283.711 35.6184C274.739 35.6184 266.356 29.0006 266.356 17.7881C266.356 6.57571 274.697 0 283.711 0ZM283.711 30.1387C289.483 30.1387 295.169 26.0499 295.169 17.7881C295.169 9.52634 289.483 5.4376 283.711 5.4376C277.94 5.4376 272.296 9.52634 272.296 17.7881C272.296 26.0499 277.982 30.1387 283.711 30.1387Z"
            fill="url(#paint4_linear_63_1289)"
          />
          <path
            d="M358.483 34.9019H352.712V6.15428H341.633V0.716675H369.646V6.15428H358.525V34.9019H358.483Z"
            fill="url(#paint5_linear_63_1289)"
          />
          <path
            d="M400.144 20.3173H384.095V34.9441H378.324V0.716675H384.095V14.8376H400.144V0.716675H405.958V34.9019H400.144V20.2752V20.3173Z"
            fill="url(#paint6_linear_63_1289)"
          />
          <path
            d="M104.722 34.9019L107.924 26.3451L104.722 34.9019Z"
            fill="url(#paint7_linear_63_1289)"
          />
          <path
            d="M118.624 0.716675H111.926L98.6143 34.9019H104.722L115.127 6.99732L125.616 34.9019H131.893L118.624 0.716675Z"
            fill="url(#paint8_linear_63_1289)"
          />
          <path
            d="M51.2662 0.716675H30.1616V6.11213H51.2662V0.716675Z"
            fill="url(#paint9_linear_63_1289)"
          />
          <path
            d="M51.2662 29.5065H30.1616V34.9019H51.2662V29.5065Z"
            fill="url(#paint10_linear_63_1289)"
          />
          <path
            d="M45.4529 15.1748H31.5938V20.4438H45.4529V15.1748Z"
            fill="url(#paint11_linear_63_1289)"
          />
          <path
            d="M165.677 22.5513C165.087 24.4482 163.992 26.3029 162.349 27.6939C160.664 29.127 158.39 30.0544 155.483 30.0544C149.67 30.0544 144.151 25.7549 144.151 17.7882C144.151 9.82145 149.796 5.39549 155.441 5.39549C158.474 5.39549 160.791 6.36498 162.434 7.8403L167.657 5.01612C165.003 1.98118 160.959 -0.0421143 155.441 -0.0421143C146.595 -0.0421143 138.254 6.66005 138.254 17.7882C138.254 28.9163 146.3 35.5763 155.483 35.5763C160.833 35.5763 164.877 33.4687 167.531 30.5181C169.216 28.6634 170.353 26.4715 170.943 24.2374L165.635 22.5092L165.677 22.5513Z"
            fill="url(#paint12_linear_63_1289)"
          />
          <path
            d="M334.935 11.0861C334.935 5.31124 330.849 0.716675 324.236 0.716675H311.135V5.81706H323.182C326.805 5.81706 329.08 7.84036 329.08 11.0861C329.08 14.3318 326.805 16.3129 323.182 16.3129H311.135V34.9019H316.948V21.4133H321.203L328.364 34.9019H334.935L327.226 20.8232C332.029 19.6851 334.935 15.9335 334.935 11.0861Z"
            fill="url(#paint13_linear_63_1289)"
          />
          <path
            d="M104.722 34.9019L107.924 26.3451L104.722 34.9019Z"
            fill="url(#paint14_linear_63_1289)"
          />
          <path
            d="M118.624 0.716675H111.926L98.6143 34.9019H104.722L115.127 6.99732L125.616 34.9019H131.893L118.624 0.716675Z"
            fill="url(#paint15_linear_63_1289)"
          />
          <path
            d="M116.391 67.4431V75.5363H112.895V55.0083H120.772C124.732 55.0083 127.175 57.7482 127.175 61.2468C127.175 64.1553 125.448 66.3893 122.542 67.1059L127.175 75.5363H123.258L118.961 67.4431H116.434H116.391ZM123.637 61.2468C123.637 59.3078 122.289 58.0854 120.098 58.0854H116.349V64.366H120.098C122.247 64.366 123.637 63.1015 123.637 61.2468Z"
            fill="white"
          />
          <path
            d="M175.114 55.0505H178.568V72.2485H187.962V75.5363H175.114V55.0083V55.0505Z"
            fill="white"
          />
          <path
            d="M198.198 75.5363H194.744V58.2962H188.088V55.0083H204.896V58.2962H198.198V75.5363Z"
            fill="white"
          />
          <path
            d="M212.141 55.0504L217.07 63.6494L221.999 55.0504H225.916L218.671 66.8951V75.5784H215.216V66.8951L208.055 55.0504H212.141Z"
            fill="white"
          />
          <path
            d="M239.27 55.0505H242.724V72.2485H252.118V75.5363H239.27V55.0083V55.0505Z"
            fill="white"
          />
          <path
            d="M257.341 55.0505H260.796V72.2485H270.189V75.5363H257.341V55.0083V55.0505Z"
            fill="white"
          />
          <path
            d="M273.475 65.2934C273.475 58.5912 278.488 54.5868 283.796 54.5868C289.104 54.5868 292.094 57.6639 293.021 61.2468L289.735 62.3428C289.104 59.8558 287.208 57.8746 283.796 57.8746C280.384 57.8746 277.014 60.3195 277.014 65.2934C277.014 70.2673 280.299 72.6278 283.796 72.6278C287.292 72.6278 289.188 70.4359 289.904 68.1176L293.063 69.1714C292.137 72.6278 289.019 75.9578 283.796 75.9578C278.277 75.9578 273.433 71.9956 273.433 65.2934H273.475Z"
            fill="white"
          />
          <path
            d="M153.714 75.5362L155.609 70.3937L153.714 75.5362Z"
            fill="white"
          />
          <path
            d="M162.012 55.0504H158.011L150.007 75.5363H153.714L159.948 58.8019L166.225 75.5363H170.016L162.012 55.0504Z"
            fill="white"
          />
          <path
            d="M145.963 55.0504H133.284V58.2961H145.963V55.0504Z"
            fill="white"
          />
          <path
            d="M145.963 72.3328H133.284V75.5785H145.963V72.3328Z"
            fill="white"
          />
          <path
            d="M134.126 66.8529H142.467V63.6915H134.126V66.8529Z"
            fill="white"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_63_1289"
            x1="10.6997"
            y1="34.9019"
            x2="10.6997"
            y2="0.716675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_63_1289"
            x1="75.4038"
            y1="35.6184"
            x2="75.4038"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_63_1289"
            x1="190.531"
            y1="9.14128e-05"
            x2="190.531"
            y2="9.14128e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_63_1289"
            x1="242.008"
            y1="9.14128e-05"
            x2="242.008"
            y2="9.14128e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_63_1289"
            x1="283.754"
            y1="35.6184"
            x2="283.754"
            y2="0"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_63_1289"
            x1="355.619"
            y1="9.14128e-05"
            x2="355.619"
            y2="9.14128e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_63_1289"
            x1="392.183"
            y1="9.13671e-05"
            x2="392.183"
            y2="9.13671e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_63_1289"
            x1="106.323"
            y1="34.9019"
            x2="106.323"
            y2="26.3451"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_63_1289"
            x1="115.254"
            y1="34.9019"
            x2="115.254"
            y2="0.716675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_63_1289"
            x1="40.6929"
            y1="6.11213"
            x2="40.6929"
            y2="9.13102e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_63_1289"
            x1="40.6929"
            y1="9.63776e-05"
            x2="40.6929"
            y2="29.5065"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_63_1289"
            x1="38.5444"
            y1="20.4438"
            x2="38.5444"
            y2="15.1748"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_63_1289"
            x1="154.641"
            y1="35.6184"
            x2="154.641"
            y2="3.62368e-05"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_63_1289"
            x1="323.014"
            y1="34.9019"
            x2="323.014"
            y2="0.716675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_63_1289"
            x1="106.323"
            y1="34.9019"
            x2="106.323"
            y2="26.3451"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_63_1289"
            x1="115.254"
            y1="34.9019"
            x2="115.254"
            y2="0.716675"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#E9BE96" />
            <stop offset="1" stopColor="#A27156" />
          </linearGradient>
          <clipPath id="clip0_63_1289">
            <rect width="406" height="76" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </Box>
  );
}
