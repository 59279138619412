import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
// hooks
import useOffSetTop from "../../../hooks/useOffSetTop";
import useResponsive from "../../../hooks/useResponsive";
// utils
import cssStyles from "../../../utils/cssStyles";
// config
import { HEADER, NAVBAR } from "../../../config";
// components
import Logo from "../../../components/Logo";
import Iconify from "../../../components/Iconify";
import { IconButtonAnimate } from "../../../components/animate";
//
import AccountPopover from "./AccountPopover";

import style from "./style.module.scss";
import { Fragment, useCallback } from "react";
import classNames from "classnames";
import { useSwitchLocaleMutation } from "../../../stores/features/api/apisSlice";
import { useDispatch, useSelector } from "react-redux";
import { setLocale } from "../../../state/slices/localeSlice";
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) =>
    prop !== "isCollapse" && prop !== "isOffset" && prop !== "verticalLayout",
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: "none",
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 1,
  transition: theme.transitions.create(["width", "height"], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: "100%",
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({
  onOpenSidebar,
  isCollapse = false,
  verticalLayout = false,
}) {
  const dispatch = useDispatch();
  const [switchLocale] = useSwitchLocaleMutation();
  const { locale: currentLocale } = useSelector((state) => state.locale);

  const isOffset =
    useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const isDesktop = useResponsive("up", "lg");

  const handleChangeLanguage = useCallback((language) => {
    switchLocale(language).then(() => {
      dispatch(setLocale(language));
    });
  }, []);

  return (
    <RootStyle
      isCollapse={isCollapse}
      isOffset={isOffset}
      verticalLayout={verticalLayout}
    >
      <Toolbar
        sx={{
          px: { lg: 5 },
        }}
        className={style.toolbar}
      >
        {isDesktop && verticalLayout && (
          <Logo sx={{ mr: 2.5 }} isCollapse={isCollapse} />
        )}

        {!isDesktop && (
          <IconButtonAnimate
            onClick={onOpenSidebar}
            sx={{ mr: 1, color: "text.primary" }}
          >
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
          <Stack display="flex" flexDirection="row" gap={1}>
            {[
              { code: "en_US", label: "EN" },
              { code: "vi_VN", label: "VN" },
            ].map((language, index, array) => (
              <Fragment key={index}>
                <IconButton
                  variant="text"
                  size="small"
                  className={classNames(style.language, {
                    [style.active]: currentLocale === language.code,
                  })}
                  onClick={() => handleChangeLanguage(language.code)}
                >
                  <Typography sx={{ color: "#FFF", fontSize: "14px" }}>
                    {language.label}
                  </Typography>
                </IconButton>
                {index !== array.length - 1 && (
                  <Divider orientation="vertical" variant="middle" flexItem />
                )}
              </Fragment>
            ))}
          </Stack>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
