import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { filter, forEach, join, keyBy, map, method } from "lodash";
import { makeHttpClient } from "../../../api/factory";
import handleError from "../../../api/handle-error";
import { ApiPath } from "../../../api/v1";
// https://redux.js.org/tutorials/essentials/part-7-rtk-query-basics
// https://redux.js.org/tutorials/essentials/part-8-rtk-query-advanced
// https://redux-toolkit.js.org/tutorials/overview

const responseDataExtractor = (responseData) => responseData.data;
const responseDataKeyBy = (key) => (responseData) =>
  keyBy(responseDataExtractor(responseData), key);
const pagingRequestParams = (paging) => {
  const params = map(paging, (val, key) => {
    if (key === "page") {
      return `page=${val}`;
    }
    if (key === "limit") {
      return `limit=${val}`;
    }
    if (key === "sort") {
      return `sort=${val}`;
    }
    if (key === "direction") {
      return `direction=${val}`;
    }
  });

  return join(params, "&");
};
const searchRequestParams = (search) => {
  if (!search) {
    return;
  }

  const searchParams = map(search, (value, key) => {
    if (value === Object(value)) {
      return `${key}=${value}`;
    }
    const params = map(value, (v, k) => {
      if (k === "operator") {
        return `${key}[o]=${v}`;
      }
      if (k === "field") {
        return `${key}[f]=${v}`;
      }
      if (k === "value") {
        return `${key}[v]=${v}`;
      }
      if (k === "group") {
        return `${key}[g]=${v}`;
      }
      if (k === "model") {
        return `${key}[m]=${v}`;
      }
      if (k === "from") {
        return `${key}[from]=${v}`;
      }
      if (k === "to") {
        return `${key}[to]=${v}`;
      }

      return "";
    });

    return join(params, "&");
  });

  return join(searchParams, "&");
};
const getEntities =
  (basePath) =>
    (search = null) =>
      `/${basePath}?${searchRequestParams(search)}`;
const pagingEntities =
  (basePath) =>
    (paging = null, search = null) =>
      `/${basePath}?${pagingRequestParams(paging)}&${searchRequestParams(
        search
      )}`;
const pagingEntitiesByTag =
  (basePath) =>
    (tag, paging = null, search = null) =>
      `/${basePath}/get-by-tag/${tag}?${pagingRequestParams(
        paging
      )}&${searchRequestParams(search)}`;
const pagingEntitiesByCategory =
  (basePath) =>
    (category, paging = null, search = null) =>
      `/${basePath}/get-by-category/${category}?${pagingRequestParams(
        paging
      )}&${searchRequestParams(search)}`;
const getEntityById = (basePath) => (id) => `/${basePath}/get/${id}`;
const getEntityByType = (basePath) => (type) => `/${basePath}/get-by-type/${type}`;
const getEntityBySlug = (basePath) => (slug) =>
  `/${basePath}/get-by-slug/${slug}`;
const postData = (basePath) => (data) => ({
  url: `/${basePath}`,
  method: "POST",
  body: data,
});
const postUrl = (basePath) => (pathParams) => ({
  url: `/${basePath}/${pathParams}`,
  method: "POST",
});
const addEntity = (basePath) => (entity) => ({
  url: `/${basePath}/add`,
  method: "POST",
  body: entity,
});
const editEntity = (basePath) => (id, entity) => ({
  url: `/${basePath}/edit/${id}`,
  method: "POST",
  body: entity,
});
const removeEntity = (basePath) => (id) => ({
  url: `/${basePath}/delete/${id}`,
  method: "DELETE",
});

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
    async (query) => {
      try {
        const { client, extractor } = makeHttpClient({
          baseUrl: baseUrl,
          withCredentials: true,
        });

        const { url, method, body, params, headers } =
          typeof query === "string" ? { url: query, method: "GET" } : query;
        const res = await handleError(
          client.request({
            url: url,
            method: method,
            data: body,
            params: params,
            headers: headers,
          }),
          true
        );

        return { data: extractor(res) };
      } catch (axiosError) {
        const err = axiosError;

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export const apisSlice = createApi({
  reducerPath: "api",
  baseQuery: axiosBaseQuery({ baseUrl: ApiPath }),
  endpoints: (builder) => ({
    getLocalizations: builder.query({
      query: getEntities("localizations"),
      providesTags: ["localizations"],
      transformResponse: responseDataKeyBy("name"),
    }),
    getSystemConfigs: builder.query({
      query: getEntities("system-configs?limit=1000"),
      providesTags: ["system-configs"],
      transformResponse: responseDataKeyBy("name"),
    }),
    getAboutUs: builder.query({
      query: getEntities("about-us"),
      providesTags: ["about-us"],
      transformResponse: responseDataExtractor,
    }),
    getNews: builder.query({
      query: pagingEntities(`news`),
      providesTags: ["news"],
    }),
    getNewsByCategory: builder.query({
      query: pagingEntitiesByCategory(`news`),
      providesTags: ["news"],
    }),
    getNewsByTag: builder.query({
      query: pagingEntitiesByTag(`news`),
      providesTags: ["news"],
    }),
    getNewsSliderItems: builder.query({
      query: getEntities(`news/slider-items`),
      providesTags: ["news"],
      transformResponse: responseDataExtractor,
    }),
    getNew: builder.query({
      query: getEntityBySlug(`news`),
      providesTags: ["news"],
      transformResponse: responseDataExtractor,
    }),
    getTeams: builder.query({
      query: pagingEntities(`teams`),
      providesTags: ["teams"],
    }),
    getTags: builder.query({
      query: pagingEntities(`tags`),
      providesTags: ["tags"],
    }),
    getTag: builder.query({
      query: getEntityBySlug(`tags`),
      providesTags: ["tags"],
      transformResponse: responseDataExtractor,
    }),
    getServices: builder.query({
      query: pagingEntities(`services`),
      providesTags: ["services"],
    }),
    getServicesByCategory: builder.query({
      query: pagingEntitiesByCategory(`services`),
      providesTags: ["services"],
    }),
    getService: builder.query({
      query: getEntityBySlug(`services`),
      providesTags: ["services"],
      transformResponse: responseDataExtractor,
    }),
    getProjects: builder.query({
      query: pagingEntities(`projects`),
      providesTags: ["projects"],
    }),
    getProjectsByCategory: builder.query({
      query: pagingEntitiesByCategory(`projects`),
      providesTags: ["projects"],
    }),
    getProjectsSliderItems: builder.query({
      query: getEntities(`projects/slider-items`),
      providesTags: ["projects"],
      transformResponse: responseDataExtractor,
    }),
    getProjectsFeatureItems: builder.query({
      query: getEntities(`projects/feature-items`),
      providesTags: ["projects"],
      transformResponse: responseDataExtractor,
    }),
    getProject: builder.query({
      query: getEntityBySlug(`projects`),
      providesTags: ["projects"],
      transformResponse: responseDataExtractor,
    }),
    getPartners: builder.query({
      query: pagingEntities(`partners`),
      providesTags: ["partners"],
    }),
    getOurCoreValues: builder.query({
      query: pagingEntities(`our-core-values`),
      providesTags: ["our-core-values"],
    }),
    getOurCoreValue: builder.query({
      query: getEntityBySlug(`our-core-values`),
      providesTags: ["our-core-values"],
      transformResponse: responseDataExtractor,
    }),
    getMilestones: builder.query({
      query: pagingEntities(`milestones`),
      providesTags: ["milestones"],
    }),
    getLocale: builder.query({
      query: getEntities("locales/current-locale"),
      transformResponse: responseDataExtractor,
    }),
    getLocales: builder.query({
      query: pagingEntities(`locales`),
      providesTags: ["locales"],
    }),
    getCategories: builder.query({
      query: pagingEntities(`categories`),
      providesTags: ["categories"],
    }),
    getCategory: builder.query({
      query: getEntityBySlug(`categories`),
      providesTags: ["categories"],
      transformResponse: responseDataExtractor,
    }),
    getCategoriesByType: builder.query({
      query: getEntityByType(`categories`),
      providesTags: ["categories"],
      transformResponse: responseDataExtractor,
    }),
    //Mutation
    sendContactUs: builder.mutation({
      query: postData(`contact-us`),
    }),
    setDefaultLocale: builder.mutation({
      query: postUrl(`locales/set-default-locale`),
      invalidatesTags: ["locales"],
    }),
    switchLocale: builder.mutation({
      query: postUrl(`locales/switch`),
      invalidatesTags: [
        "locales",
        "categories",
        "localizations",
        "milestones",
        "our-core-values",
        "partners",
        "projects",
        "services",
        "system-configs",
        "tags",
        "teams",
        "news",
        "about-us",
      ],
    }),
    addLocale: builder.mutation({
      query: addEntity(`locales`),
      invalidatesTags: ["locales"],
    }),
    editLocale: builder.mutation({
      query: editEntity(`locales`),
      invalidatesTags: ["locales"],
    }),
    removeLocale: builder.mutation({
      query: removeEntity(`locales`),
      invalidatesTags: ["locales"],
    }),
    addCategories: builder.mutation({
      query: addEntity(`categories`),
      invalidatesTags: ["categories"],
    }),
    editCategories: builder.mutation({
      query: editEntity(`categories`),
      invalidatesTags: ["categories"],
    }),
    removeCategories: builder.mutation({
      query: removeEntity(`categories`),
      invalidatesTags: ["categories"],
    }),
    addLocalizations: builder.mutation({
      query: addEntity(`localizations`),
      invalidatesTags: ["localizations"],
    }),
    editLocalizations: builder.mutation({
      query: editEntity(`localizations`),
      invalidatesTags: ["localizations"],
    }),
    removeLocalizations: builder.mutation({
      query: removeEntity(`localizations`),
      invalidatesTags: ["localizations"],
    }),
    addMilestones: builder.mutation({
      query: addEntity(`milestones`),
      invalidatesTags: ["milestones"],
    }),
    editMilestones: builder.mutation({
      query: editEntity(`milestones`),
      invalidatesTags: ["milestones"],
    }),
    removeMilestones: builder.mutation({
      query: removeEntity(`milestones`),
      invalidatesTags: ["milestones"],
    }),
    addOurCoreValues: builder.mutation({
      query: addEntity(`our-core-values`),
      invalidatesTags: ["our-core-values"],
    }),
    editOurCoreValues: builder.mutation({
      query: editEntity(`our-core-values`),
      invalidatesTags: ["our-core-values"],
    }),
    removeOurCoreValues: builder.mutation({
      query: removeEntity(`our-core-values`),
      invalidatesTags: ["our-core-values"],
    }),
    addPartners: builder.mutation({
      query: addEntity(`partners`),
      invalidatesTags: ["partners"],
    }),
    editPartners: builder.mutation({
      query: editEntity(`partners`),
      invalidatesTags: ["partners"],
    }),
    removePartners: builder.mutation({
      query: removeEntity(`partners`),
      invalidatesTags: ["partners"],
    }),
    addProjects: builder.mutation({
      query: addEntity(`projects`),
      invalidatesTags: ["projects"],
    }),
    editProjects: builder.mutation({
      query: editEntity(`projects`),
      invalidatesTags: ["projects"],
    }),
    removeProjects: builder.mutation({
      query: removeEntity(`projects`),
      invalidatesTags: ["projects"],
    }),
    addServices: builder.mutation({
      query: addEntity(`services`),
      invalidatesTags: ["services"],
    }),
    editServices: builder.mutation({
      query: editEntity(`services`),
      invalidatesTags: ["services"],
    }),
    removeServices: builder.mutation({
      query: removeEntity(`services`),
      invalidatesTags: ["services"],
    }),
    addSystemConfigs: builder.mutation({
      query: addEntity(`system-configs`),
      invalidatesTags: ["system-configs"],
    }),
    editSystemConfigs: builder.mutation({
      query: editEntity(`system-configs`),
      invalidatesTags: ["system-configs"],
    }),
    removeSystemConfigs: builder.mutation({
      query: removeEntity(`system-configs`),
      invalidatesTags: ["system-configs"],
    }),
    addTags: builder.mutation({
      query: addEntity(`tags`),
      invalidatesTags: ["tags"],
    }),
    editTags: builder.mutation({
      query: editEntity(`tags`),
      invalidatesTags: ["tags"],
    }),
    removeTags: builder.mutation({
      query: removeEntity(`tags`),
      invalidatesTags: ["tags"],
    }),
    addTeams: builder.mutation({
      query: addEntity(`teams`),
      invalidatesTags: ["teams"],
    }),
    editTeams: builder.mutation({
      query: editEntity(`teams`),
      invalidatesTags: ["teams"],
    }),
    removeTeams: builder.mutation({
      query: removeEntity(`teams`),
      invalidatesTags: ["teams"],
    }),
    addNews: builder.mutation({
      query: addEntity(`news`),
      invalidatesTags: ["news"],
    }),
    editNews: builder.mutation({
      query: editEntity(`news`),
      invalidatesTags: ["news"],
    }),
    removeNews: builder.mutation({
      query: removeEntity(`news`),
      invalidatesTags: ["news"],
    }),
    addAboutUs: builder.mutation({
      query: addEntity(`about-us`),
      invalidatesTags: ["about-us"],
    }),
    editAboutUs: builder.mutation({
      query: editEntity(`about-us`),
      invalidatesTags: ["about-us"],
    }),
  }),
});

// Generated hooks
export const {
  useGetLocalizationsQuery,
  useGetSystemConfigsQuery,
  useGetAboutUsQuery,
  useGetNewsQuery,
  useGetNewsByCategoryQuery,
  useGetNewsByTagQuery,
  useGetNewsSliderItemsQuery,
  useGetNewQuery,
  useGetTeamsQuery,
  useGetTagsQuery,
  useGetTagQuery,
  useGetServicesQuery,
  useGetServicesByCategoryQuery,
  useGetServiceQuery,
  useGetProjectsQuery,
  useGetProjectsByCategoryQuery,
  useGetProjectsSliderItemsQuery,
  useGetProjectsFeatureItemsQuery,
  useGetProjectQuery,
  useGetPartnersQuery,
  useGetOurCoreValuesQuery,
  useGetOurCoreValueQuery,
  useGetMilestonesQuery,
  useGetLocaleQuery,
  useGetLocalesQuery,
  useGetCategoriesQuery,
  useGetCategoriesByTypeQuery,
  useGetCategoryQuery,
  useSetDefaultLocaleMutation,
  useSwitchLocaleMutation,
  useAddLocaleMutation,
  useEditLocaleMutation,
  useRemoveLocaleMutation,
  useAddNewsMutation,
  useEditNewsMutation,
  useRemoveNewsMutation,
  useAddAboutUsMutation,
  useEditAboutUsMutation,
  useSendContactUsMutation,
  useAddCategoriesMutation,
  useEditCategoriesMutation,
  useRemoveCategoriesMutation,
  useAddLocalizationsMutation,
  useEditLocalizationsMutation,
  useRemoveLocalizationsMutation,
  useAddMilestonesMutation,
  useEditMilestonesMutation,
  useRemoveMilestonesMutation,
  useAddOurCoreValuesMutation,
  useEditOurCoreValuesMutation,
  useRemoveOurCoreValuesMutation,
  useAddPartnersMutation,
  useEditPartnersMutation,
  useRemovePartnersMutation,
  useAddProjectsMutation,
  useEditProjectsMutation,
  useRemoveProjectsMutation,
  useAddServicesMutation,
  useEditServicesMutation,
  useRemoveServicesMutation,
  useAddSystemConfigsMutation,
  useEditSystemConfigsMutation,
  useRemoveSystemConfigsMutation,
  useAddTagsMutation,
  useEditTagsMutation,
  useRemoveTagsMutation,
  useAddTeamsMutation,
  useEditTeamsMutation,
  useRemoveTeamsMutation,
} = apisSlice;
