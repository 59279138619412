import { groupBy } from 'lodash';
import { TYPES } from './actions';

export const initialState = {
  user: {},
  configs: {},
};

const reducer = (state, { type, ...args }) => {
  switch (type) {
    case TYPES.GET_USER: {
      const { user } = args;
      return {
        ...state,
        user,
      };
    }

    case TYPES.LOGOUT_USER: {
      return {
        ...state,
        ...initialState,
      };
    }

    case TYPES.GET_CONFIGS: {
      const { configs } = args;
      return {
        ...state,
        configs: configs.reduce(
          (pre, item) => ({ ...pre, [item.name]: item }),
          {}
        ),
      };
    }

    default:
      return state;
  }
};

export default reducer;
