import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locale: "vi_VN",
};

export const localSlice = createSlice({
  name: "localSlice",
  initialState,
  reducers: {
    setLocale(state, action) {
      state.locale = action.payload;
    },
  },
});

export const { setLocale } = localSlice.actions;

export default localSlice.reducer;
