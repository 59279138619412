import { groupBy, remove } from 'lodash';
import { PRODUCT_ATTRIBUTE_TYPE } from '../../../constants';
import { TYPES } from './actions';

export const initialState = {
  attributes: {},
  filters: {},
  compareItems: [],
  products: [],
};

const reducer = (state, { type, ...args }) => {
  switch (type) {
    case TYPES.GET_ATTRIBUTES: {
      const { attributes } = args;
      return {
        ...state,
        attributes: attributes.map((item) => {
          if (item.inputType === PRODUCT_ATTRIBUTE_TYPE.CHOICE) {
            return { ...item, value: JSON.parse(item.value) };
          }
          return item;
        }),
      };
    }

    case TYPES.UPDATE_FILTERS: {
      const { name, value, checked } = args;
      let currentValue = [];
      if (state.filters[name]) {
        currentValue = [...state.filters[name]];
      }
      if (checked) {
        currentValue.push(value);
      } else {
        remove(currentValue, (item) => item === value);
      }
      const newState = {
        ...state,
        filters: {
          ...state.filters,
          [name]: currentValue,
        },
      };
      if (currentValue?.length === 0) {
        delete newState.filters[name];
      }

      return newState;
    }

    case TYPES.UPDATE_COMPARE_ITEMS: {
      const { item, reset } = args;
      if (reset) {
        return { ...state, compareItems: [] };
      }
      const compareItems = [...state.compareItems];
      const foundItem = compareItems.find((i) => i.id === item.id);
      if (foundItem) {
        remove(compareItems, (i) => i.friendlyName === item.friendlyName);
      } else {
        compareItems.push(item);
      }
      return { ...state, compareItems };
    }

    case TYPES.UPDATE_PRODUCTS: {
      const { products } = args;
      return { ...state, products, compareItems: [] };
    }

    case TYPES.CLEAR_FILTER: {
      return { ...state, filters: {} };
    }

    default:
      return state;
  }
};

export default reducer;
