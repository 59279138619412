import { combineReducers, createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./user";
import { apisSlice } from "./features/api/apisSlice";
import configSlice from "../state/slices/configSlice";
import localeSlice from "../state/slices/localeSlice";

const rootReducers = combineReducers({ user: userReducer });
// export const store = createStore(rootReducers,  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());

export const store = configureStore({
  reducer: {
    [apisSlice.reducerPath]: apisSlice.reducer,
    config: configSlice,
    locale: localeSlice,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apisSlice.middleware);
  },
});

store.subscribe(() => console.log("store state", store.getState()));
