import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  getAuthenticatedUser,
  getEntities,
  getLogoutUser,
} from '../../../api/v1';
import { STATUS_ENTITY } from '../../../constants';
import { map } from 'lodash';

export const TYPES = {
  GET_USER: 'GET_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  GET_CONFIGS: 'GET_CONFIGS',
};

export function useActionCreator(dispatch) {
  const { enqueueSnackbar } = useSnackbar();

  const onGetAuthenticatedUser = useCallback(
    (username, password) =>
      getAuthenticatedUser({ username, password }).then(({data}) => {
        enqueueSnackbar('Đăng nhập thành công', { variant: 'success' });
        const {details: user} = data;
        user.roleNames = map(user.roles, "name");
        return dispatch({
          type: TYPES.GET_USER,
          user,
        });
      }),
    [dispatch]
  );

  const onGetConfigs = useCallback(
    () =>
      getEntities('system-configs', {
        sort: 'order_sort',
        direction: 'asc',
        limit: 1000,
      }).then(({ data }) => {
        return dispatch({
          type: TYPES.GET_CONFIGS,
          configs: data,
        });
      }),
    [dispatch]
  );

  const onLogoutUser = useCallback(
    () =>
      getLogoutUser().then(() => {
        window.localStorage.id_token = null;
        dispatch({
          type: TYPES.LOGOUT_USER,
        });
        enqueueSnackbar('Đăng xuất thành công', { variant: 'success' });
        return Promise.resolve();
      }),
    [dispatch]
  );

  return {
    onGetAuthenticatedUser,
    onLogoutUser,
    onGetConfigs,
  };
}
