// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import { ROLES } from '../../../constants';
import SettingsIcon from '@mui/icons-material/Settings';
import BugReportTwoToneIcon from '@mui/icons-material/BugReportTwoTone'; // ----------------------------------------------------------------------
import GroupsIcon from '@mui/icons-material/Groups';
import CategoryIcon from '@mui/icons-material/Category';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CollectionsIcon from '@mui/icons-material/Collections';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import QrCodeIcon from '@mui/icons-material/QrCode';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GridViewIcon from '@mui/icons-material/GridView';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: getIcon('ic_user'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const sidebarConfig = [
  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    // roles: [ROLES.ADMIN],
    items: [
      {
        title: 'Categories',
        path: '/dashboard/categories',
        icon: <CategoryIcon />,
        permissions: ['ManageAll', 'ManageCategories']
      },
      {
        title: 'Configurations',
        path: '/dashboard/system-configs',
        icon: <SettingsIcon />,
        permissions: ['ManageAll', 'ManageSystemConfigs']
      },
      {
        title: 'Locales',
        path: '/dashboard/locales',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageLocales']
      },
      {
        title: 'Localizations',
        path: '/dashboard/localizations',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageLocalizations']
      },
      {
        title: 'Milestones',
        path: '/dashboard/milestones',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageMilestones']
      },
      {
        title: 'News',
        path: '/dashboard/news',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageNews']
      },
      {
        title: 'Our Core Values',
        path: '/dashboard/our-core-values',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageOurCoreValues']
      },
      {
        title: 'Social Responsibilities',
        path: '/dashboard/social-responsibilities',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageSocialResponsibilities']
      },
      {
        title: 'Projects',
        path: '/dashboard/projects',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageProjects']
      },
      {
        title: 'Services',
        path: '/dashboard/services',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageServices']
      },
      {
        title: 'Partners',
        path: '/dashboard/partners',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManagePartners']
      },
      {
        title: 'Teams',
        path: '/dashboard/teams',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageTeams']
      },
      {
        title: 'Users',
        path: '/dashboard/users',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageUsers']
      },
      {
        title: 'Roles',
        path: '/dashboard/roles',
        icon: <QrCodeIcon />,
        permissions: ['ManageAll', 'ManageRoles']
      },
    ],
  },
  {
    subheader: 'document',
    items: [
      {
        title: 'help',
        path: '/dashboard/help',
        icon: <HelpOutlineIcon />,
      },
    ],
  },

  //TESTING
  // {
  //   subheader: 'testing',
  //   roles: [ROLES.ADMIN],
  //   items: [
  //     {
  //       title: 'test form',
  //       path: '/dashboard/test/form',
  //       icon: <BugReportTwoToneIcon />,
  //     },
  //   ],
  // },
];

export default sidebarConfig;
