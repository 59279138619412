import React, { useReducer, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

import reducer, { initialState } from './reducer';
import { useActionCreator } from './actions';

const Context = createContext();

const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = useActionCreator(dispatch, state);

  return (
    <Context.Provider value={{ ...state, ...actions }}>
      {children}
    </Context.Provider>
  );
};

ContextProvider.propTypes = {
  children: PropTypes.any,
};

export const withProductContextProvider = (Component) => {
  const Wrapper = (props) => (
    <ContextProvider>
      <Component {...props} />
    </ContextProvider>
  );
  return Wrapper;
};

export const useProductContext = () => useContext(Context);
