import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { useProductContext, withProductContextProvider } from './context';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ProductLayout = () => {
  const { onGetAttributes } = useProductContext();
  useEffect(() => {
    onGetAttributes();
  }, []);
  return (
    <>
      <Outlet />
    </>
  );
};

export default withProductContextProvider(ProductLayout);
