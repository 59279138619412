import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/material";

import style from "./style.module.scss";
import classNames from "classnames";
import { LogoLN } from "../assets";
// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({
  disabledLink = false,
  sx,
  isCollapse,
  textColor = "#2E3192",
}) {
  const theme = useTheme();

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }} className={style.logo}>
      <div
        className={classNames(style.companyName, {
          [style.hidden]: isCollapse,
        })}
      >
        <div className={style.textLogo}>
          <LogoLN height={40} width={210} />
        </div>
      </div>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <div className={style.logoWrapper}>
      <RouterLink to="/">{logo}</RouterLink>
    </div>
  );
}
