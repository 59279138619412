import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import { List, Box, ListSubheader } from "@mui/material";
//
import { NavListRoot } from "./NavList";
import { useDashboardContext } from "../../../layouts/container/context";
import { ROLES } from "../../../constants";
import { intersection } from "lodash";
import { canRender, hasPermission } from "../../../utils";
import Scrollbars from "react-custom-scrollbars-2";
// ----------------------------------------------------------------------

export const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  transition: theme.transitions.create("opacity", {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

NavSectionVertical.propTypes = {
  isCollapse: PropTypes.bool,
  navConfig: PropTypes.array,
};

export default function NavSectionVertical({
  navConfig,
  isCollapse = false,
  ...other
}) {
  const { user } = useDashboardContext();
  return (
    <Scrollbars autoHide style={{ height: "calc(100vh - 180px)" }}>
      <Box {...other}>
        {navConfig
          .filter((group) => !group.roles || canRender(user.roleNames, group.roles))
          .map((group) => (
            <List key={group.subheader} disablePadding sx={{ px: 2 }}>
              <ListSubheaderStyle
                sx={{
                  ...(isCollapse && {
                    opacity: 0,
                  }),
                }}
              >
                {group.subheader}
              </ListSubheaderStyle>

              {group.items
              .filter((item) => !item.permissions || hasPermission(user.roles ?? [], item.permissions))
              .map((list) => (
                <NavListRoot
                  key={list.title}
                  list={list}
                  isCollapse={isCollapse}
                />
              ))}
            </List>
          ))}
      </Box>
    </Scrollbars>
  );
}
