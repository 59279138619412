import { useEffect } from "react";
import { Outlet } from "react-router-dom";

import { withDashboardContextProvider, useDashboardContext } from "./context";
import { useGetSystemConfigsQuery } from "@apisSlice";
import { useDispatch, useSelector } from "react-redux";
import { setConfigs } from "@slices/configSlice";
import { setLocale } from "@slices/localeSlice";
import { useGetLocaleQuery } from "@apisSlice";

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

const ContainerLayout = () => {
  const dispatch = useDispatch();
  const { data: configs, refetch: refetchConfigs } = useGetSystemConfigsQuery({
    limit: 1000,
  });
  const { locale: currentLocale } = useSelector((state) => state.locale);
  const { data: initLocale } = useGetLocaleQuery();

  const { onGetAuthenticatedUser, user } = useDashboardContext();
  useEffect(() => {
    if (!user.login) {
      onGetAuthenticatedUser();
    }
  }, []);

  useEffect(() => {
    dispatch(setLocale(initLocale));
  }, [initLocale]);

  useEffect(() => {
    refetchConfigs();
  }, [currentLocale]);

  useEffect(() => {
    dispatch(setConfigs(configs));
  }, [configs]);

  return (
    <>
      <Outlet />
    </>
  );
};

export default withDashboardContextProvider(ContainerLayout);
