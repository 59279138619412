const handleError = (ajaxObj, useInlineMessage) =>
  ajaxObj.catch((e) => {
    const { response, message } = e;

    if (!response) {
      if (message && !useInlineMessage) {
        console.error(message);
      }
      throw Error(message || e);
    } else {
      const { status, statusText, data } = response;
      if (status === 401 || status === 403) {
        window.location = '/user/login';
      }
      if (!useInlineMessage) {
        console.error(statusText, data && data.message);
      }
      throw Error((data && data.message) || e);
    }
  });

export default handleError;
