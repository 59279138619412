import Humps, { decamelize, decamelizeKeys } from 'humps';
import axios from 'axios';
import { omit, isNumber, isEmpty, get, isArray } from 'lodash';
import queryString from 'query-string';

import handleError from './handle-error';

export const makeHttpClient = ({
  baseUrl,
  csrfToken,
  withCredentials = true,
}) => {
  const client = axios.create({
    baseURL: baseUrl,
    origin: true,
    withCredentials: withCredentials,
    transformResponse: (data) =>
      data ? Humps.camelizeKeys(JSON.parse(data)) : {},
    transformRequest: [decamelizeKeys, JSON.stringify],
    // paramsSerializer: (params) =>
    //   queryString.stringify(
    //     omit(params, (value) => {
    //       if (isNumber(value)) {
    //         return false;
    //       }
    //       return isEmpty(value);
    //     }),
    //     {
    //       arrayFormat: 'bracket',
    //     }
    //   ),
  });

  client.interceptors.request.use(
    async (config) => {
      config.headers = {
        // Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
        // 'X-Requested-With': 'XMLHttpRequest',
        // 'X-CSRF-Token': csrfToken,
      };
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  const extractor = (res) => {
    if (typeof res.data === 'string') {
      res.data = JSON.parse(res.data);
    }
    const { data, headers } = res;
    const totalCount = get(headers, 'x-total-count');
    const contentType = get(headers, 'content-type');

    if (contentType?.includes('image/')) {
      return { data, contentType };
    }

    let output = { data: data.data, totalCount, contentType };

    if (data.paging) {
      const { paging } = data;
      output = { ...output, totalCount: paging.count, currentCount: paging.current, paging };
    }

    return output;
  };

  const getJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(
      client.get(url, {
        ...options,
        params: data,
      }),
      useInlineMessage
    ).then(extractor);

  const patchJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(
      client.patch(url, {
        ...options,
        params: data,
      }),
      useInlineMessage
    ).then(extractor);

  const postJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(client.post(url, data, options), useInlineMessage).then(
      extractor
    );
  const putJSON = (url, data = {}, options = {}, useInlineMessage) =>
    handleError(client.put(url, data, options), useInlineMessage).then(
      extractor
    );
  const deleteJSON = (url, options = {}, useInlineMessage) =>
    handleError(client.delete(url, options), useInlineMessage).then(extractor);

  return {
    client,
    extractor,
    getJSON,
    patchJSON,
    postJSON,
    putJSON,
    deleteJSON,
  };
};
