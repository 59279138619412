import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

export const configSlice = createSlice({
  name: "configSlice",
  initialState,
  reducers: {
    setConfigs(state, action) {
      state.data = action.payload;
    },
  },
});

export const { setConfigs } = configSlice.actions;

export default configSlice.reducer;
