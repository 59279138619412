import axios from 'axios';
import { makeHttpClient } from './factory';
import { replace } from 'lodash';

// const HostName = 'http://localhost:8765/';
// const HostName = 'http://nguyenminhnhut.com/';
const HostName = 'https://legacynorthgroup.com/';
export const ApiPath = `${HostName}api/`;
const { getJSON, deleteJSON, putJSON, postJSON } = makeHttpClient({
  baseUrl: ApiPath,
  withCredentials: true,
});

export const getAuthenticatedUser = (user) => { return postJSON('/users/login', user); }
  
export const getLogoutUser = (user) => postJSON('/users/logout', user);

export const getEntity = (entity, id) => getJSON(`/${entity}/get/${id}`);

export const getEntities = (entity, params) => getJSON(`/${entity}`, params);

export const updateEntity = (entity, id, payload) =>
  putJSON(`/${entity}/edit/${id}`, payload, {
    message: 'Update Successfully!',
  });

export const saveNewEntity = (entity, payload) =>
  postJSON(`/${entity}/add`, payload);

export const deleteEntity = (entity, id) =>
  deleteJSON(`/${entity}/delete/${id}`);

export const saveImage = (data) =>
  postJSON('/file-manager/save', data, {
    transformResponse: [],
    transformRequest: [],
  });

export const cleanImages = (data) =>
  postJSON('/file-manager/clean', data, {
    transformResponse: [],
    transformRequest: [],
  });

export const getImage = ({ path }) =>
  fetch(`${ApiPath}files?path=${path}`)
    .then((data) => data.blob())
    .then((img) => {
      const src = URL.createObjectURL(img);
      return src;
    });

export const getImageUrl = ({ path }) => `${HostName}images/${path}`;
export const getDocUrl = ({ path }) => `${HostName}docs/${path}`;

export const postFilterProducts = (payload) =>
  postJSON(`/product-attribute/filter`, payload);

export const sentContactMail = (payload) => postJSON(`/mail/contact`, payload);
export const sentQuoteMail = (payload) => postJSON(`/mail/quote`, payload);

export const getImagePathFromUrl = (url) =>
  replace(url, `${ApiPath}files?path=`, '');
export const deleteImage = (path) => deleteJSON(`/files/delete?path=${path}`);
