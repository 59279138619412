import { useCallback } from 'react';
import { getEntities, getImageUrl, postFilterProducts } from '../../../api/v1';
import { STATUS_ENTITY } from '../../../constants';

export const TYPES = {
  GET_ATTRIBUTES: 'GET_ATTRIBUTES',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_COMPARE_ITEMS: 'UPDATE_COMPARE_ITEMS',
  DELETE_COMPARE_ITEM: 'DELETE_COMPARE_ITEM',
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
  CLEAR_FILTER: 'CLEAR_FILTER',
};

export function useActionCreator(dispatch) {
  const onGetProducts = useCallback(
    () =>
      getEntities('product', {
        sort: 'order_sort,asc',
        status: STATUS_ENTITY.PUBLISH,
        type: 'ARTICLE',
      }).then(({ items }) => {
        const productItems = items.map((item) => {
          const photo = JSON.parse(item?.photo);
          return {
            ...item,
            photo: getImageUrl({ path: photo[0].original }),
          };
        });
        return dispatch({
          type: TYPES.UPDATE_PRODUCTS,
          products: productItems,
        });
      }),
    [dispatch]
  );

  const onGetAttributes = useCallback(
    () =>
      getEntities('attribute', {
        sort: 'order_sort,asc',
        status: STATUS_ENTITY.PUBLISH,
        limit: 1000,
      }).then(({ items }) =>
        dispatch({
          type: TYPES.GET_ATTRIBUTES,
          attributes: items,
        })
      ),
    [dispatch]
  );

  const onUpdateFilters = useCallback(
    ({ name, value, checked }) =>
      dispatch({
        type: TYPES.UPDATE_FILTERS,
        name,
        value,
        checked,
      }),
    [dispatch]
  );

  const onUpdateProducts = useCallback(
    (filters) => {
      postFilterProducts(filters).then(({ items }) => {
        const productItems = items.map((item) => {
          const photo = JSON.parse(item?.photo);
          return {
            ...item,
            photo: getImageUrl({ path: photo[0].original }),
          };
        });
        return dispatch({
          type: TYPES.UPDATE_PRODUCTS,
          products: productItems,
        });
      });
    },
    [dispatch]
  );

  const onUpdateCompareItems = useCallback(
    (item, reset) =>
      dispatch({
        type: TYPES.UPDATE_COMPARE_ITEMS,
        item,
        reset,
      }),
    [dispatch]
  );

  const onDeleteCompareItem = useCallback(
    (item) =>
      dispatch({
        type: TYPES.DELETE_COMPARE_ITEM,
        item,
      }),
    [dispatch]
  );

  const onClearFilter = useCallback(
    () =>
      dispatch({
        type: TYPES.CLEAR_FILTER,
      }),
    [dispatch]
  );

  return {
    onGetProducts,
    onUpdateProducts,
    onGetAttributes,
    onUpdateFilters,
    onUpdateCompareItems,
    onDeleteCompareItem,
    onClearFilter,
  };
}
