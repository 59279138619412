
const initUser = {
    level: "guest"
}
export const userReducer = (state = initUser , action) => {
    switch (action.type) {
        case "user/setLevel":{
            return {
                ...state,
                level: action.payload
            }
        }
        default:
            return state;
    }
}
