export const INPUT_TYPE = {
  STRING: "STRING",
  OPTION: "OPTION",
  DATE: "DATE",
  DATETIME: "DATETIME",
  NUMBER: "NUMBER",
  RADIO: "RADIO",
  TAGS: "TAGS",
  EDITOR: "EDITOR",
  CHECKBOX: "CHECKBOX",
  SWITCH: "SWITCH",
  IMAGE: "IMAGE",
  PDF: "PDF",
  VIDEO: "VIDEO",
  ICON: "ICON",
};

export const CATEGORY_TYPE = {
  PRODUCT: "PRODUCT",
  PROJECT: "PROJECT",
  APPLICATION: "APPLICATION",
  ARTICLE: "ARTICLE",
  HOME: "HOME",
};

export const PRODUCT_ATTRIBUTE_TYPE = {
  LABEL: "LABEL",
  CHOICE: "CHOICE",
  PARAGRAPH: "PARAGRAPH",
  ICON: "ICON",
};

export const DATA_TYPE = {
  STRING: "STRING",
  NUMBER: "NUMBER",
  BOOLEAN: "BOOLEAN",
};

export const DEFAULT_VALUE = {
  STRING: "",
  LABEL: "",
  OPTION: [],
  NUMBER: 0,
  RADIO: null,
  TAGS: [],
  CHOICE: [],
  EDITOR: "",
  PARAGRAPH: "",
  CHECKBOX: true,
  SWITCH: true,
  IMAGE: null,
};

export const HOME_PAGE = "/dashboard/home";
export const PAGE_SIZE = 25;

export const ROLES = {
  USER: "ROLE_USER",
  ADMIN: "ROLE_ADMIN",
  EDITOR: "ROLE_EDITOR",
};

export const STATUS_ENTITY = {
  SHOW: "Show",
  HIDE: "Hide",
  PUBLISH: "Publish",
  DRAFT: "Draft",
};

export const FIELD_TYPES = {
  TEXT: "TEXT",
  EDITOR: "EDITOR",
  IMAGE: "IMAGE",
  BOOLEAN: "BOOLEAN",
  DATE: "DATE",
  DATETIME: "DATETIME",
  INTEGER: "INTEGER",
  NUMERIC: "NUMERIC",
  CURRENCY: "CURRENCY",
  NUMBER: "NUMBER",
  OPTION: "OPTION",
  LINK: "LINK",
  COLOR: "COLOR",
  PERCENT: "PERCENT",
  PASSWORD: "PASSWORD",
  CHECKBOX: "CHECK BOX",
  RADIOBOX: "RADIO BOX",
  CODE_EDITOR: "CODE EDITOR",
  TAGS: "TAGS",
  MATRIX: "MATRIX",
};

export const ENTITY = {
  SYSTEM_CONFIGURATIONS: "SYSTEM_CONFIGURATIONS",
};

// TODO

export const PATH_PAGE = {
  about: {
    title: "About us",
    path: "/about",
  },
  projects: {
    title: "Projects",
    path: "/projects",
  },
  propertyManagement: {
    title: "Property management",
    path: "/property-management",
  },
  socialResponsibility: {
    title: "Social Responsibility",
    path: "/social-responsibility",
  },
  contact: {
    title: "Contact",
    path: "/contact",
  },
};

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 84,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const PATH_DOCS = {
  root: "https://docs.minimals.cc",
  changelog: "https://docs.minimals.cc/changelog",
};

export const PATH_MINIMAL_ON_STORE =
  "https://mui.com/store/items/minimal-dashboard/";

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};
